<footer class="footer-two">
    <div class="footer-widget-area pt-100 pb-50 {{layout}}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12 order-1">
                    <!-- Site Info Widget -->
                    <div class="widget site-info-widget mb-50">
                        <div class="logo-white mb-50">
                            <img [src]="logo" alt="logo">
                        </div>
                        <p>
                          At Residence Krengo Lama, we blend taste with comfort to create an unforgettable experience just for you.
                          Join us for a meal, a night, or a longer stay – we are here to make your visit delightful.
                        </p>
                        <div class="social-links mt-40">
                            <a><i class="fab fa-facebook-f"></i></a>
                            <a><i class="fab fa-twitter"></i></a>
                            <a><i class="fab fa-behance"></i></a>
                            <a><i class="fab fa-linkedin"></i></a>
                            <a><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 order-3 order-lg-2">
                    <div class="widget nav-widget mb-50">
                        <div>
                            <h4 class="widget-title">Services.</h4>
                            <ul>
                                <li><a href="#">Resturent & Bar</a></li>
                                <li><a href="#">Gaming Zone</a></li>
                                <li><a href="#">Swimming Pool</a></li>
                                <li><a href="#">Marrige Party</a></li>
                                <li><a href="#">Restaurant</a></li>
                                <li><a href="#">Party Planning</a></li>
                                <li><a href="#">Conference Room</a></li>
                                <li><a href="#">Tour Consultancy</a></li>
                                <li><a href="#">Coctail Party Houses</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-6 col-sm-12 order-2">
                    <!-- Contact Widget -->
                    <div class="widget contact-widget mb-50">
                        <h4 class="widget-title">Contact Us</h4>
                        <div class="contact-lists">
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Phone Number</h6>
                                    +23675090909
                                </div>
                            </div>
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-message"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Email Address</h6>
                                    <a href="#">marcellama&#64;gmail.com</a>
                                </div>
                            </div>
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-location-pin"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Office Address</h6>
                                    Rue 04 – 084 N°112, Av. des Martyrs, Bangui, Central African Republic
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area pt-30 pb-30">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5 order-2 order-md-1">
                    <p class="copyright-text copyright-two">© Copyright 2024 <a href="#">Residence Krengo Lama</a> All Rights Reserved.</p>
                </div>
                <!-- <div class="col-lg-6 col-md-7 order-1 order-md-2">
                    <div class="footer-menu text-center text-md-right">
                        <ul>
                            <li><a href="#">Terms of use</a></li>
                            <li><a href="#">Privacy Environmental Policy</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</footer>
