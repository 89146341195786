import { createAction, props } from '@ngrx/store';
import { Room } from 'src/app/models/roomObject';
import { AvailibilityRequestDto } from 'src/app/models/availibility-request-dto';
import { Booking } from 'src/app/models/booking';

export const checkRoomAvailability = createAction(
  '[Booking] Check Room Availability',
  props<{ availabilityRequest: AvailibilityRequestDto }>()
);

export const checkRoomAvailabilitySuccess = createAction(
  '[Booking] Check Room Availability Success',
  props<{ rooms: Room[] }>()
);

export const checkRoomAvailabilityFailure = createAction(
  '[Booking] Check Room Availability Failure',
  props<{ error: any }>()
);

export const bookRoom = createAction(
  '[Booking] Book Room',
  props<{ bookingRequest: Booking }>()
);

export const bookRoomSuccess = createAction(
  '[Booking] Book Room Success',
  props<{ booking: Booking }>()
);

export const bookRoomFailure = createAction(
  '[Booking] Book Room Failure',
  props<{ error: any }>()
);
