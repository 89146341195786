import { Component } from '@angular/core';
import data from '../../../data/navigation.json';
import { HelperService } from 'src/app/services/helper.service';
import { Navigation } from 'src/app/models/navigation';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent extends HelperService {
  public navigation: Navigation[] = data;
  constructor(public helperService: HelperService) {
    super();
  }
}
