<p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
<section class="breadcrumb-area" [style.background-image]="'url(assets/images/icon/pattern-01.png)'">
  <div class="container">
    <div class="breadcrumb-text">
      <span>The ultimate luxury</span>
      <h2 class="page-title">{{title}}</h2>
    </div>

    <!-- TO DO: Add Breadcrumb -->

    <!-- <div *ngIf="breadcrumbs && breadcrumbs.length" class="breadcrumb-text">
      <span>The ultimate luxury</span>
      <h2 class="page-title">{{ breadcrumbs[breadcrumbs.length - 1].label }}</h2>
      <ul class="breadcrumb-nav">
        <li><a routerLink='/'>Home</a></li>
        <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
          <a *ngIf="!last" [routerLink]="breadcrumb.routerLink">{{ breadcrumb.label }}</a>
          <span *ngIf="last" class="active">{{ breadcrumb.label }}</span>
        </li>
      </ul>
    </div> -->
  </div>
</section>

