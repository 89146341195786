<div class="sidebar">
    <!-- Search Widget -->
    <div class="widget search-widget mb-40">
        <h5 class="widget-title">Search Objects</h5>
        <form (ngSubmit)="this.blogHelperService.onSubmit()" method="GET">
            <input type="text" name="searchText" [(ngModel)]="this.blogHelperService.searchText" placeholder="Search your keyword..." required>
            <button type="submit"><i class="far fa-search"></i></button>
        </form>
    </div>
    <!-- Popular Post Widget -->
    <div class="widget popular-feeds mb-40">
        <h5 class="widget-title">Recent Feeds</h5>
        <div class="popular-feed-loop">
            <div class="single-popular-feed" *ngFor="let item of this.blogHelperService.recentPost()">
                <div class="feed-img">
                    <img [src]="item.gridImage" [alt]="item.title">
                </div>
                <div class="feed-desc">
                    <h6><a routerLink="/blog-details/{{item.id}}">{{item.title}}</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> {{item.postDate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Categories Widget -->
    <div class="widget categories-widget mb-40">
        <h5 class="widget-title">Categories</h5>
        <ul>
            <li *ngFor="let item of this.blogHelperService.category">
                <a routerLink="/blog/cat/{{item.id}}">{{item.title}}<span>{{item.count}}</span></a>
            </li>
        </ul>
    </div>
    <!-- Social Icon Widget -->
    <div class="widget socail-widget mb-40">
        <h5 class="widget-title">Never Miss News</h5>
        <ul>
            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#"><i class="fab fa-behance"></i></a></li>
            <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
        </ul>
    </div>
    <!-- Twitter Feeds Widget -->
    <div class="widget twitter-feed-widget mb-40">
        <h5 class="widget-title">Twitter Feeds</h5>
        <div class="twitter-looop">
            <div class="single-twitter" *ngFor="let item of twitterpost">
                <a href="#">
                    {{item.tweet}}
                </a>
                <span class="date">{{item.tweetDate}}</span>
            </div>
        </div>
    </div>
    <!-- Instagram Feeds Widget -->
    <div class="widget instagram-feed-widget mb-40">
        <h5 class="widget-title">Instagram Feeds</h5>
        <ul>
            <li *ngFor="let item of instagram">
                <img [src]="item.img" alt="image">
            </li>
        </ul>
    </div>
    <!-- Popular Tags Widget -->
    <div class="widget popular-tag-widget mb-40">
        <h5 class="widget-title">Popular Tags</h5>
        <ul>
            <li *ngFor="let item of this.blogHelperService.tags">
                <a routerLink="/blog/tag/{{item.id}}">{{item.title}}</a>
            </li>
        </ul>
    </div>
    <!-- Banner Ad Widget -->
    <div class="widget banner-ad-widget">
        <img src="assets/images/banner-widget.jpg" alt="image">
    </div>
</div>
