import { Component, OnInit } from '@angular/core';
import data from '../../../data/navigation.json';
import { Navigation } from 'src/app/models/navigation';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css']
})
export class CanvasComponent implements OnInit {
  public navigation: Navigation[] = data;
  constructor() { }

  ngOnInit(): void {
  }

}
