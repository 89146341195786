<div class="booking-form-inner">
  <form (submit)="onSubmit($event)">
    <div class="row align-items-end">
      <div class="col-lg-3 col-md-6">
        <div class="inputs-filed mt-30">
          <label for="arrival-date">Arrival Date</label>
          <input
            type="date"
            name="arrivalDate"
            id="arrival-date"
            #arrivalDate
            [value]="currentDate"
            (change)="onArrivalDateChange($event)"
            (keydown)="disableKeyInput($event)">
          <div *ngIf="arrivalDateError" class="error">{{ arrivalDateError }}</div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="inputs-filed mt-30">
          <label for="departure-date">Departure Date</label>
          <input
            type="date"
            name="departureDate"
            id="departure-date"
            #departureDate
            [value]="nextDate"
            [min]="nextDate"
            (change)="onDepartureDateChange($event)"
            (keydown)="disableKeyInput($event)">
          <div *ngIf="departureDateError" class="error">{{ departureDateError }}</div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="inputs-filed mt-30">
          <label for="guests">Guests</label>
          <select name="guests" id="guests" #guests (change)="onGuestsChange($event)">
            <option value="" disabled selected>Select From Here</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
          </select>
          <div *ngIf="guestsError" class="error">{{ guestsError }}</div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="inputs-filed mt-30">
          <button
            type="submit"
            [ngClass]="(loading$ | async) ? 'check-availability-button-loading' : 'check-availability-button'"
            [disabled]="loading$ | async">
            <ng-container *ngIf="loading$ | async; else buttonText">
              <div class="spinner"></div>
            </ng-container>
            <ng-template #buttonText>
              Check Availability
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
