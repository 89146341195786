import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { AvailibilityRequestDto } from 'src/app/models/availibility-request-dto';
import { Booking } from 'src/app/models/booking';
import { Guest } from 'src/app/models/guest';
import { Room } from 'src/app/models/roomObject';
import { bookRoom } from 'src/app/store/actions/booking.actions';
import { BookingState } from 'src/app/store/states/booking.state';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrl: './booking-dialog.component.css'
})
export class BookingDialogComponent implements OnInit {

  error: {} | undefined;
  guest: Guest ={
    firstName: '',
    lastName: '',
    emailAddress: '',
    physicalAddress: '',
    city: '',
    country: '',
    zipCode: 0,
    phoneNumber: '',
    specialRequest: '',
  }
  availabilityRequest: AvailibilityRequestDto = {
    checkInDate: '',
    checkOutDate: '',
    totalGuests: 0
  };

  constructor(
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<BookingDialogComponent>,
    private store: Store<{booking: BookingState}>,
    @Inject(MAT_DIALOG_DATA) public room: Room
  ) { }

  ngOnInit(): void {
    this.getStoredRequest();
    this.getStoredRoom();
  }

  onSubmit(contactForm: NgForm): void {
    if (contactForm.invalid) {
      contactForm.form.markAllAsTouched();
      return;
    }

    const newGuid = this.generateGUID()
    const booking: Booking = {
      bookingId: newGuid,
      roomId: this.room.roomId,
      checkInDate: this.availabilityRequest.checkInDate,
      checkOutDate: this.availabilityRequest.checkOutDate,
      guest: this.guest,
      totalPrice: this.getTotalPrice(),
    }
    this.store.dispatch(bookRoom({ bookingRequest: booking }));

    this.store.select(state => state.booking).pipe(
      filter(bookingState => !bookingState.loading),
      take(1)
    ).subscribe(bookingState => {
      if (!bookingState.error) {
        this.dialogRef.close();
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private getStoredRequest(){
    const storedRequest = localStorage.getItem('availabilityRequest');
    if(storedRequest){
      this.availabilityRequest = JSON.parse(storedRequest) as AvailibilityRequestDto;
    }
    else{
      const today = new Date();
      const currentDate = this.formatDate(today);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const nextDate = this.formatDate(tomorrow);

      // Default request DTO
      this.availabilityRequest = {
        checkInDate: currentDate,
        checkOutDate: nextDate,
        totalGuests: 2
      }
    }
  }

  private getStoredRoom(){
    if(!this.room.roomId){
      const storedRoom = localStorage.getItem('selectedRoom');
      if(storedRoom){
        this.room = JSON.parse(storedRoom) as Room;
      }
    }
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private generateGUID(): string {
    return uuidv4();
  }

  private getTotalPrice(): number{
    const numberOfDays = this.getNumberOfDays(this.availabilityRequest.checkInDate, this.availabilityRequest.checkOutDate);
    const totalPrice = this.room.pricePerNight * numberOfDays;
    return totalPrice;
  }

  private getNumberOfDays(arrival: string, departure: string): number {
    const arrivalDate = new Date(arrival);
    const departureDate = new Date(departure);
    const timeDifference = departureDate.getTime() - arrivalDate.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    return dayDifference;
  }

}
