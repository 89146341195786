<div class="row">
    <div class="col-lg-3 col-md-6 col-6" *ngFor="let item of counter">
        <div class="counter-box">
            <div class="icon">
                <img [src]="item.icon" alt="">
            </div>
            <h4><span class="counter">{{item.count}}</span>{{item.prefix}}<span class="plus-icon">+</span></h4>
            <span class="title">{{item.title}}</span>
        </div>
    </div>
</div>