import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

export interface EmailData {
  to: string;
  subject: string;
  text: string;
  html?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private functionUrl = 'https://us-central1-residence-krengo-lama-2e1bc.cloudfunctions.net/sendEmail';

  constructor(private http: HttpClient) { }

  sendEmail(emailData: EmailData): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.functionUrl, emailData, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';

    if (error.error instanceof ErrorEvent) {
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
