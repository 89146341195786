import { Component, OnInit } from '@angular/core';
import { Room } from 'src/app/models/roomObject';
import { Router } from '@angular/router';
import { AvailibilityRequestDto } from 'src/app/models/availibility-request-dto';
import { Store } from '@ngrx/store';
import { filter, Observable, switchMap, take } from 'rxjs';
import { checkRoomAvailability } from 'src/app/store/actions/booking.actions';
import { BookingState } from 'src/app/store/states/booking.state';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css']
})
export class BookingFormComponent implements OnInit {
  currentDate: string;
  nextDate: string;

  arrivalDateError: string | null = null;
  departureDateError: string | null = null;
  guestsError: string | null = null;

  availableRooms$: Observable<Room[]>;
  loading$: Observable<boolean>;
  error$: Observable<any>;

  constructor(private store: Store<{booking: BookingState}>, private router: Router) {
    const today = new Date();
    this.currentDate = this.formatDate(today);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    this.nextDate = this.formatDate(tomorrow);

    this.availableRooms$ = this.store.select(state => state.booking.availableRooms);
    this.loading$ = this.store.select(state => state.booking.loading);
    this.error$ = this.store.select(state => state.booking.error);
  }

  ngOnInit(): void {

  }

  onSubmit(event: Event): void {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const arrivalDate = this.getInputValue(form, '#arrival-date');
    const departureDate = this.getInputValue(form, '#departure-date');
    const guests = this.getInputValue(form, '#guests');

    if (this.validateForm(arrivalDate, departureDate, guests)) {
      const availabilityRequest: AvailibilityRequestDto = {
        checkInDate: arrivalDate,
        checkOutDate: departureDate,
        totalGuests: parseInt(guests, 10)
      }

      this.store.dispatch(checkRoomAvailability({ availabilityRequest }));

      this.loading$
      .pipe(
        filter(loading => !loading),
        switchMap(() => this.availableRooms$),
        filter(rooms => rooms.length > 0),
        take(1)
      )
      .subscribe(() => {
        localStorage.setItem('availabilityRequest', JSON.stringify(availabilityRequest))
        this.router.navigate(['/room-list']);
      });
    }
  }

  disableKeyInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  onArrivalDateChange(event: Event): void {
    const arrivalDate = (event.target as HTMLInputElement).value;
    if (!arrivalDate) {
      this.arrivalDateError = 'Please select an arrival date.';
      return;
    }

    const minDepartureDate = new Date(arrivalDate);
    minDepartureDate.setDate(minDepartureDate.getDate() + 1);
    this.nextDate = this.formatDate(minDepartureDate);
    this.validateForm(arrivalDate, this.getInputValue(null, '#departure-date'), this.getInputValue(null, '#guests'));
  }

  onDepartureDateChange(event: Event): void {
    const departureDate = (event.target as HTMLInputElement).value;
    this.validateForm(this.getInputValue(null, '#arrival-date'), departureDate, this.getInputValue(null, '#guests'));
  }

  onGuestsChange(event: Event): void {
    const guests = (event.target as HTMLSelectElement).value;
    this.validateForm(this.getInputValue(null, '#arrival-date'), this.getInputValue(null, '#departure-date'), guests);
  }

  private validateForm(arrivalDate: string, departureDate: string, guests: string): boolean {
    this.resetErrors();

    if (!arrivalDate) {
      this.arrivalDateError = 'Please select an arrival date.';
    }
    if (!departureDate) {
      this.departureDateError = 'Please select a departure date.';
    }
    if (!guests) {
      this.guestsError = 'Please select the number of guests.';
    }

    return !this.arrivalDateError && !this.departureDateError && !this.guestsError;
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private resetErrors(): void {
    this.arrivalDateError = null;
    this.departureDateError = null;
    this.guestsError = null;
  }

  private getInputValue(form: HTMLFormElement | null, selector: string): string {
    const element = form
      ? form.querySelector(selector) as HTMLInputElement | HTMLSelectElement
      : document.querySelector(selector) as HTMLInputElement | HTMLSelectElement;
    return element.value.trim();
  }
}
