import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuItem } from 'primeng/api';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  breadcrumbs: MenuItem[] = [];

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    // Initialize breadcrumb items
    this.breadcrumbs = this.getBreadcrumbs();
    this.updateTitle();
  }

  onActivate(_event: any) {
    window.scroll(0, 0);
  }

  private getBreadcrumbs(): MenuItem[] {
    // Example breadcrumb structure
    return [
      { label: 'Home', routerLink: '/' },
      { label: 'Page 1', routerLink: '/page1' },
      { label: 'Page 2', routerLink: '/page2' }
    ];
  }

  private updateTitle(): void {
    const defaultTitle = 'Residence Krengo Lama';
    const breadcrumbTitles = this.breadcrumbs.map(crumb => crumb.label).join(' | ');
    this.titleService.setTitle(`${defaultTitle} | ${breadcrumbTitles}`);
  }
}
