import { createReducer, on } from '@ngrx/store';
import { checkRoomAvailability, checkRoomAvailabilitySuccess, checkRoomAvailabilityFailure, bookRoom, bookRoomSuccess, bookRoomFailure } from '../actions/booking.actions';
import { initialState } from '../states/booking.state';

export const bookingReducer = createReducer(
  initialState,
  on(checkRoomAvailability, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(checkRoomAvailabilitySuccess, (state, { rooms }) => ({
    ...state,
    availableRooms: rooms,
    loading: false
  })),
  on(checkRoomAvailabilityFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false
  })),
  on(bookRoom, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(bookRoomSuccess, (state, { booking }) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(bookRoomFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  }))
);
