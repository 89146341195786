import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-sidebar',
  templateUrl: './room-sidebar.component.html',
  styleUrls: ['./room-sidebar.component.css']
})
export class RoomSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
