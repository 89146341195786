import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BookingService } from 'src/app/services/booking.service';
import {
  checkRoomAvailability,
  checkRoomAvailabilitySuccess,
  checkRoomAvailabilityFailure,
  bookRoom,
  bookRoomSuccess,
  bookRoomFailure} from '../actions/booking.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class BookingEffects {

  checkRoomAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkRoomAvailability),
      mergeMap(({ availabilityRequest }) =>
        this.bookingService.checkRoomAvailability(availabilityRequest).pipe(
          map(rooms => {
            if (rooms.length === 0) {
              this.messageService.add(
                {
                  severity: 'warn',
                  summary: 'No Rooms Available',
                  detail: 'There are no rooms available for the selected dates.'
                }
              );
            }
            return checkRoomAvailabilitySuccess({ rooms });
          }),
          catchError(error => {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to check room availability. Please try again later.'
              }
            );
            return of(checkRoomAvailabilityFailure({ error }));
          })
        )
      )
    )
  );

  bookRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bookRoom),
      mergeMap(({ bookingRequest }) =>
        this.bookingService.bookRoom(bookingRequest).pipe(
          map(() => {
            this.messageService.add(
              {
                severity: 'success',
                summary: 'Booking Confirmed',
                detail: 'The room has been successfully booked. A confirmation email will be sent to you shortly.',
                life: 9000
              }
            );
            return bookRoomSuccess({ booking: bookingRequest });
          }),
          catchError(error => {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Booking Failed',
                detail: 'Failed to book the room. Please try again later.',
                life: 9000
              }
            );
            return of(bookRoomFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private bookingService: BookingService,
    private messageService: MessageService,
  ) {}
}
