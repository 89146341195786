<!-- Search Widget -->
<div class="widget search-widget">
    <h5 class="widget-title">Search room</h5>
    <form>
        <input type="text" placeholder="Search your keyword...">
        <button type="submit"><i class="far fa-search"></i></button>
    </form>
</div>
<!-- About Widget -->
<div class="widget about-widget">
    <h5 class="widget-title">About us</h5>
    <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia reiciendis illo ipsa asperiores,
        perspiciatis corrupti veritatis assumenda architecto commodi provident quas necessitatibus
        consequatur praesentium magnam optio deserunt fugiat repellat culpa.
    </p>
</div>
<!-- Nav Widget -->
<div class="widget nav-widget">
    <h5 class="widget-title">Our pages</h5>
    <ul>
        <li class="menu-item" [ngClass]="item.child == true ? 'menu-item-has-children' : ''"
            *ngFor="let item of navigation | slice:4:10">
            <a href="javascript:void(0)"
                *ngIf="item.child">{{item.linkText}}</a>
            <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
            <ul class="submenu" *ngIf="item.child">
                <li class="menu-item" [ngClass]="item.child == true ? 'menu-item-has-children' : ''"
                    *ngFor="let item of item.submenu">
                    <a href="javascript:void(0)"
                        *ngIf="item.child">{{item.linkText}}</a>
                    <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                    <ul class="submenu" *ngIf="item.child">
                        <li class="menu-item" *ngFor="let item of item.submenu">
                            <a routerLink="{{item.link}}">{{item.linkText}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</div>
<!-- Social Link -->
<div class="widget social-link">
    <h5 class="widget-title">Contact with us</h5>
    <ul>
        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
        <li><a href="#"><i class="fab fa-behance"></i></a></li>
        <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
        <li><a href="#"><i class="fab fa-google"></i></a></li>
    </ul>
</div>