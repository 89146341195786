import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Room } from 'src/app/models/roomObject';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() roomListArray: Room[] | undefined;
  @Input() room: Room | undefined;

  breadcrumbs: MenuItem[] = [];
  availableRooms: Room[] = [];
  title: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      { label: 'Home', routerLink: '/' },
      { label: 'Page 1', routerLink: '/page1' },
      { label: 'Page 2', routerLink: '/page2' }
    ];

    if(this.roomListArray){
      this.title = 'Available rooms';
    }

    if(this.room){
      this.title = this.room.roomType;
    }

    switch(this.router.url){
      case '/contact':
        this.title = 'Contact Us';
        break;
      case '/about':
        this.title = 'About Us';
        break;
    }
  }
}
