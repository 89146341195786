import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, combineLatest, from, map, Observable } from 'rxjs';
import { Room } from '../models/roomObject';
import { Booking } from '../models/booking';
import { AvailibilityRequestDto } from '../models/availibility-request-dto';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private firestore: AngularFirestore, private router: Router) {}

  checkRoomAvailability(availibilityRequestDto: AvailibilityRequestDto): Observable<Room[]> {

    // Get all rooms from the "rooms" collection
    const rooms$ = this.firestore.collection<Room>('rooms').valueChanges({ idField: 'roomId' });
    // Get all bookings that overlap with the given dates
    const bookings$ = this.firestore.collection<Booking>('bookings', ref => ref
      .where('checkInDate', '<', availibilityRequestDto.checkOutDate)
      .where('checkOutDate', '>', availibilityRequestDto.checkInDate)
    ).valueChanges();

    return combineLatest([rooms$, bookings$]).pipe(
      map(([rooms, bookings]) => {
        const bookedRoomIds = new Set<string>();
        bookings.forEach(booking => {
          bookedRoomIds.add(booking.roomId);
        });

        return rooms.filter(room => {
          const isBooked = bookedRoomIds.has(room.roomId);
          const canAccommodateGuests = room.totalGuests >= availibilityRequestDto.totalGuests;
          return !isBooked && canAccommodateGuests;
        });
      })
    );
  }

  bookRoom(bookingRequestDto: Booking){
    return from(this.firestore.collection('bookings').add(bookingRequestDto)).pipe(
      map(() => {
        localStorage.removeItem('availabilityRequest');
        localStorage.removeItem('selectedRoom');
        this.router.navigate(['/home-v2']);
      }),
      catchError((error) => {
        console.error('Failed to book room:', error);
        throw error;
      })
    );
  }
}

