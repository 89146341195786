import { Room } from 'src/app/models/roomObject';

export interface BookingState {
  availableRooms: Room[];
  loading: boolean;
  error: any;
}

export const initialState: BookingState = {
  availableRooms: [],
  loading: false,
  error: null
};
