import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonFormatter'
})
export class JsonFormatterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    try {
      return JSON.stringify(value, null, 2); // pretty-print JSON with indentation
    } catch (error) {
      console.error('Error formatting JSON', error);
      return '';
    }
  }

}

