import { Component, Input } from '@angular/core';
import data from '../../../data/navigation.json';
import { HelperService } from 'src/app/services/helper.service';
import { Navigation } from 'src/app/models/navigation';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends HelperService {
  public navigation: Navigation[] = data;
  constructor(public helperService: HelperService) {
    super();
  }
  @Input()  layout: number | string | undefined;
}
