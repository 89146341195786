import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JsonFormatterPipe } from './pipes/json-formatter.pipe';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { SharedModule } from './components/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { bookingReducer } from './store/reducers/booking.reducer';
import { BookingEffects } from './store/effects/booking.effects';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule(
  {
    declarations: [
      AppComponent,
      JsonFormatterPipe
    ],
    exports: [
      JsonFormatterPipe,
    ],
    bootstrap: [
      AppComponent
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
      BrowserModule,
      StoreModule.forRoot({
        booking: bookingReducer
      }),
      EffectsModule.forRoot([
        BookingEffects
      ]),
      FormsModule,
      AppRoutingModule,
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFirestoreModule,
      BrowserAnimationsModule,
      BreadcrumbModule,
      NgbModule,
      SlickCarouselModule,
      SharedModule,
      ToastModule,
    ],
    providers: [
      provideHttpClient(withInterceptorsFromDi()),
      MessageService,
      provideAnimationsAsync()
    ]
  })
export class AppModule { }
