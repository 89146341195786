import { Component, OnInit } from '@angular/core';
import instaposts from '../../../data/instaposts.json';
import twitterpost from '../../../data/twitterpost.json';
import { BlogHelperService } from 'src/app/services/blog-helper.service';

@Component({
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.css']
})
export class BlogSidebarComponent implements OnInit {
  public instagram = instaposts;
  public twitterpost = twitterpost;

  constructor(public blogHelperService: BlogHelperService) {}

  ngOnInit(): void {
  }

}
