<h2 mat-dialog-title>Booking form</h2>
  <div class="contact-form mt-30">
    <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
      <mat-dialog-content class="mat-typography">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-30" [ngClass]="{'has-error': contactForm.form.controls['firstName']?.invalid && contactForm.form.controls['firstName']?.touched}">
              <span class="icon"><i class="far fa-user"></i></span>
              <input type="text" placeholder="First name" name="firstName" #firstName="ngModel" [(ngModel)]="guest.firstName" required>
            </div>
            <div *ngIf="firstName.invalid && firstName.touched" class="error-message">
              First name is required.
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-user"></i></span>
              <input type="text" placeholder="Last name" name="lastName" #lastName="ngModel" [(ngModel)]="guest.lastName" required>
            </div>
            <div *ngIf="lastName.invalid && lastName.touched" class="error-message">
              Last name is required.
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-envelope"></i></span>
              <input type="email" placeholder="Email address" name="emailAddress" #emailAddress="ngModel" [(ngModel)]="guest.emailAddress" required>
            </div>
            <div *ngIf="emailAddress.invalid && emailAddress.touched" class="error-message">
              A valid email address is required.
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-house"></i></span>
              <input type="text" placeholder="Physical address" name="physicalAddress" #physicalAddress="ngModel" [(ngModel)]="guest.physicalAddress" required>
            </div>
            <div *ngIf="physicalAddress.invalid && physicalAddress.touched" class="error-message">
              Physical address is required.
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-house"></i></span>
              <input type="text" placeholder="City" name="city" [(ngModel)]="guest.city">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-house"></i></span>
              <input type="text" placeholder="Country" name="country" [(ngModel)]="guest.country">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-house"></i></span>
              <input type="text" placeholder="Zip Code" name="zipCode" [(ngModel)]="guest.zipCode">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-phone"></i></span>
              <input type="text" placeholder="Phone Number" name="phoneNumber" #phoneNumber="ngModel" [(ngModel)]="guest.phoneNumber" required>
            </div>
            <div *ngIf="phoneNumber.invalid && phoneNumber.touched" class="error-message">
              Phone number is required.
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-user"></i></span>
              <input type="text" placeholder="Checkin Date" name="checkinDate" [(ngModel)]="availabilityRequest.checkInDate" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-30">
              <span class="icon"><i class="far fa-user"></i></span>
              <input type="text" placeholder="Checkout Date" name="checkoutDate" [(ngModel)]="availabilityRequest.checkOutDate" disabled>
            </div>
          </div>
          <div class="col-12">
            <div class="input-group textarea mb-30">
              <span class="icon"><i class="far fa-pen"></i></span>
              <textarea type="text" placeholder="Special Request" name="specialRequest" [(ngModel)]="guest.specialRequest"></textarea>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button type="submit" class="main-btn btn-filled">Book</button>
        <button type="button" class="main-btn  cancel-btn" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>
    </form>
  </div>
